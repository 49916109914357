import React from "react";
import "./App.css";
import Phone from "./Phone";

function App() {
  return (
    <>
      <div className="app">
        <section className="intro">
          <img src={require("./assets/logo.svg")} alt="logo" className="logo" />
          <h1 className="app-name">Covdate</h1>
          <p className="subline">COVID19 Coronavirus Statistics</p>
          <p className="tag">Home Country | Worldwide | Country-wise</p>
          <div className="btn-container">
            <a
              className="download-btn"
              href="./downloads/Covdate_1.0.apk"
              target="_blank"
              rel="noopener noreferrer"
              download
            >
              Download
            </a>
          </div>
          <p className="disclamer">
            This app uses a public&nbsp;
            <a
              href="https://github.com/pomber/covid19"
              target="_blank"
              className="link-to-api"
              rel="noopener noreferrer"
            >
              API
            </a>
            &nbsp;to fetch data, which <br />
            transforms the data from{" "}
            <a
              href="https://github.com/CSSEGISandData/COVID-19"
              target="_blank"
              className="link-to-api"
              rel="noopener noreferrer"
            >
              CSSEGISandData/COVID-19
            </a>
          </p>
          <div className="down-arrow">&lt;</div>
        </section>
        <section className="features">
          <Phone />
        </section>
      </div>
      <footer id="footer">
        <a
          href="https://www.twitter.com/devenkshp"
          target="_blank"
          className="link-to-author"
          rel="noopener noreferrer"
        >
          @devenkshp
        </a>
      </footer>
    </>
  );
}

export default App;
