import React, { Component } from "react";
import "./phone.css";

let loopImage;

let images = [];

for (let i = 1; i < 10; i++) {
  images.push(require(`./assets/screenshots/${i}.png`));
}

export default class Phone extends Component {
  state = {
    index: 0,
  };

  componentDidMount() {
    this.loopImage();
  }

  componentWillUnmount() {
    clearInterval(loopImage);
  }

  loopImage = () =>
    setInterval(() => {
      this.setState({
        index: this.state.index < 8 ? this.state.index + 1 : 0,
      });
    }, 3000);

  render() {
    return (
      <div>
        <div className="mobile">
          <div className="btns volume-rocker" />
          <div className="btns power-btn" />
          <div className="utilities">
            <div className="column camera">
              <div className="circle"></div>
            </div>
            <div className="mic"></div>
            <div className="column">
              <div className="circle"></div>
              <div className="circle"></div>
            </div>
          </div>
          <div className="screen">
            <img
              src={images[this.state.index]}
              className="screenshot"
              alt={this.state.index}
            />
          </div>
        </div>
      </div>
    );
  }
}
